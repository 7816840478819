import ResultService from "@/services/ResultService";
import { getError } from "@/utils/helpers";
export const namespaced = true;

export const state = {
  loading_data: false,
  error_data: null,
  email: localStorage.getItem("email") || "",
  step: 0,
  question_a: 0,
  question_b: 0,
  question_c: 0,
  question_d: 0,
  question_e: 0,
  question_f: 0,
  question_g: 0,
  question_h: 0,
  question_i: 0,
  question_j: 0,
  question_k: 0,
  question_l: 0,
  ready_a: false,
  ready_b: false,
  ready_c: false,
  ready_d: false,
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading_data = loading;
  },
  SET_ERROR(state, error) {
    state.error_data = error;
  },

  update_step(state, step) {
    state.step = step;
  },
  update_question_a(state, question_a) {
    state.question_a = question_a;
  },
  update_question_b(state, question_b) {
    state.question_b = question_b;
  },
  update_question_c(state, question_c) {
    state.question_c = question_c;
  },
  update_question_d(state, question_d) {
    state.question_d = question_d;
  },
  update_question_e(state, question_e) {
    state.question_e = question_e;
  },
  update_question_f(state, question_f) {
    state.question_f = question_f;
  },
  update_question_g(state, question_g) {
    state.question_g = question_g;
  },
  update_question_h(state, question_h) {
    state.question_h = question_h;
  },
  update_question_i(state, question_i) {
    state.question_i = question_i;
  },
  update_question_j(state, question_j) {
    state.question_j = question_j;
  },
  update_question_k(state, question_k) {
    state.question_k = question_k;
  },
  update_question_l(state, question_l) {
    state.question_l = question_l;
  },
  update_ready_a(state, ready_a) {
    state.ready_a = ready_a;
  },
  update_ready_b(state, ready_b) {
    state.ready_b = ready_b;
  },
  update_ready_c(state, ready_c) {
    state.ready_c = ready_c;
  },
  update_ready_d(state, ready_d) {
    state.ready_d = ready_d;
  },
};

export const getters = {
  step: (state) => {
    return state.step;
  },
  question_a: (state) => {
    return state.question_a;
  },
  question_b: (state) => {
    return state.question_b;
  },
  question_c: (state) => {
    return state.question_c;
  },
  question_d: (state) => {
    return state.question_d;
  },
  question_e: (state) => {
    return state.question_e;
  },
  question_f: (state) => {
    return state.question_f;
  },
  question_g: (state) => {
    return state.question_g;
  },
  question_h: (state) => {
    return state.question_h;
  },
  question_i: (state) => {
    return state.question_i;
  },
  question_j: (state) => {
    return state.question_j;
  },
  question_k: (state) => {
    return state.question_k;
  },
  question_l: (state) => {
    return state.question_l;
  },
  ready_a: (state) => {
    return state.ready_a;
  },
  ready_b: (state) => {
    return state.ready_b;
  },
  ready_c: (state) => {
    return state.ready_c;
  },
  ready_d: (state) => {
    return state.ready_d;
  },
};

export const actions = {
  async getDataE({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await ResultService.getResultE(payload);
      commit("update_step", response.data.data.efsmodule.step);
      commit("update_question_a", response.data.data.efsmodule.question_a);
      commit("update_question_b", response.data.data.efsmodule.question_b);
      commit("update_question_c", response.data.data.efsmodule.question_c);
      commit("update_question_d", response.data.data.efsmodule.question_d);
      commit("update_question_e", response.data.data.efsmodule.question_e);
      commit("update_question_f", response.data.data.efsmodule.question_f);
      commit("update_question_g", response.data.data.efsmodule.question_g);
      commit("update_question_h", response.data.data.efsmodule.question_h);
      commit("update_question_i", response.data.data.efsmodule.question_i);
      commit("update_question_j", response.data.data.efsmodule.question_j);
      commit("update_question_k", response.data.data.efsmodule.question_k);
      commit("update_question_l", response.data.data.efsmodule.question_l);
      commit("update_ready_a", response.data.data.efsmodule.ready_a);
      commit("update_ready_b", response.data.data.efsmodule.ready_b);
      commit("update_ready_c", response.data.data.efsmodule.ready_c);
      commit("update_ready_d", response.data.data.efsmodule.ready_d);
      commit("SET_LOADING", false);
      commit("SET_ERROR", response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_STEPONE", false);
      commit("SET_ERROR", getError(error));
    }
  },
};
