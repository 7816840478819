import ResultService from "@/services/ResultService";
import { getError } from "@/utils/helpers";
export const namespaced = true;

export const state = {
  loading_data: false,
  error_data: null,
  email: localStorage.getItem("email") || "",
  step: 0,
  sl_a: 0,
  sl_b: 0,
  sl_c: 0,
  sl_d: 0,
  sl_e: 0,
  sl_f: 0,
  sl_g: 0,
  sl_h: 0,
  sl_i: 0,
  sl_j: 0,
  sl_k: 0,
  sl_l: 0,
  sl_m: 0,
  sl_n: 0,
  sl_o: 0,
  sl_p: 0,
  sl_q: 0,
  sl_r: 0,
  sl_s: 0,
  sl_t: 0,
  sl_u: 0,
  sl_v: 0,
  sl_w: 0,
  sl_x: 0,
  sl_y: 0,
  sl_z: 0,
  sl_za: 0,
  sl_zb: 0,
  sl_zc: 0,
  sl_zd: 0,
  sl_ze: 0,
  sl_zf: 0,
  question_x: "",
  ready_a: false,
  ready_b: false,
  ready_c: false,
  ready_d: false,
  ready_e: false,
  ready_f: false,
  remark_a: "",
  remark_b: "",
  remark_c: "",
  remark_d: "",
  remark_e: "",
  remark_f: "",
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading_data = loading;
  },
  SET_ERROR(state, error) {
    state.error_data = error;
  },

  update_step(state, step) {
    state.step = step;
  },
  update_sl_a(state, sl_a) {
    state.sl_a = sl_a;
  },
  update_sl_b(state, sl_b) {
    state.sl_b = sl_b;
  },
  update_sl_c(state, sl_c) {
    state.sl_c = sl_c;
  },
  update_sl_d(state, sl_d) {
    state.sl_d = sl_d;
  },
  update_sl_e(state, sl_e) {
    state.sl_e = sl_e;
  },
  update_sl_f(state, sl_f) {
    state.sl_f = sl_f;
  },
  update_sl_g(state, sl_g) {
    state.sl_g = sl_g;
  },
  update_sl_h(state, sl_h) {
    state.sl_h = sl_h;
  },
  update_sl_i(state, sl_i) {
    state.sl_i = sl_i;
  },
  update_sl_j(state, sl_j) {
    state.sl_j = sl_j;
  },
  update_sl_k(state, sl_k) {
    state.sl_k = sl_k;
  },
  update_sl_l(state, sl_l) {
    state.sl_l = sl_l;
  },
  update_sl_m(state, sl_m) {
    state.sl_m = sl_m;
  },
  update_sl_n(state, sl_n) {
    state.sl_n = sl_n;
  },
  update_sl_o(state, sl_o) {
    state.sl_o = sl_o;
  },
  update_sl_p(state, sl_p) {
    state.sl_p = sl_p;
  },
  update_sl_q(state, sl_q) {
    state.sl_q = sl_q;
  },
  update_sl_r(state, sl_r) {
    state.sl_r = sl_r;
  },
  update_sl_s(state, sl_s) {
    state.sl_s = sl_s;
  },
  update_sl_t(state, sl_t) {
    state.sl_t = sl_t;
  },
  update_sl_u(state, sl_u) {
    state.sl_u = sl_u;
  },
  update_sl_v(state, sl_v) {
    state.sl_v = sl_v;
  },
  update_sl_w(state, sl_w) {
    state.sl_w = sl_w;
  },
  update_sl_x(state, sl_x) {
    state.sl_x = sl_x;
  },
  update_sl_y(state, sl_y) {
    state.sl_y = sl_y;
  },
  update_sl_z(state, sl_z) {
    state.sl_z = sl_z;
  },
  update_sl_za(state, sl_za) {
    state.sl_za = sl_za;
  },
  update_sl_zb(state, sl_zb) {
    state.sl_zb = sl_zb;
  },
  update_sl_zc(state, sl_zc) {
    state.sl_zc = sl_zc;
  },
  update_sl_zd(state, sl_zd) {
    state.sl_zd = sl_zd;
  },
  update_sl_ze(state, sl_ze) {
    state.sl_ze = sl_ze;
  },
  update_sl_zf(state, sl_zf) {
    state.sl_zf = sl_zf;
  },
  update_question_x(state, question_x) {
    state.question_x = question_x;
  },
  update_ready_a(state, ready_a) {
    state.ready_a = ready_a;
  },
  update_ready_b(state, ready_b) {
    state.ready_b = ready_b;
  },
  update_ready_c(state, ready_c) {
    state.ready_c = ready_c;
  },
  update_ready_d(state, ready_d) {
    state.ready_d = ready_d;
  },
  update_ready_e(state, ready_e) {
    state.ready_e = ready_e;
  },
  update_ready_f(state, ready_f) {
    state.ready_f = ready_f;
  },
  update_remark_a(state, remark_a) {
    state.remark_a = remark_a;
  },
  update_remark_b(state, remark_b) {
    state.remark_b = remark_b;
  },
  update_remark_c(state, remark_c) {
    state.remark_c = remark_c;
  },
  update_remark_d(state, remark_d) {
    state.remark_d = remark_d;
  },
  update_remark_e(state, remark_e) {
    state.remark_e = remark_e;
  },
  update_remark_f(state, remark_f) {
    state.remark_f = remark_f;
  },
};

export const getters = {
  step: (state) => {
    return state.step;
  },
  sl_a: (state) => {
    return state.sl_a;
  },
  sl_b: (state) => {
    return state.sl_b;
  },
  sl_c: (state) => {
    return state.sl_c;
  },
  sl_d: (state) => {
    return state.sl_d;
  },
  sl_e: (state) => {
    return state.sl_e;
  },
  sl_f: (state) => {
    return state.sl_f;
  },
  sl_g: (state) => {
    return state.sl_g;
  },
  sl_h: (state) => {
    return state.sl_h;
  },
  sl_i: (state) => {
    return state.sl_i;
  },
  sl_j: (state) => {
    return state.sl_j;
  },
  sl_k: (state) => {
    return state.sl_k;
  },
  sl_l: (state) => {
    return state.sl_l;
  },
  sl_m: (state) => {
    return state.sl_m;
  },
  sl_n: (state) => {
    return state.sl_n;
  },
  sl_o: (state) => {
    return state.sl_o;
  },
  sl_p: (state) => {
    return state.sl_p;
  },
  sl_q: (state) => {
    return state.sl_q;
  },
  sl_r: (state) => {
    return state.sl_r;
  },
  sl_s: (state) => {
    return state.sl_s;
  },
  sl_t: (state) => {
    return state.sl_t;
  },
  sl_u: (state) => {
    return state.sl_u;
  },
  sl_v: (state) => {
    return state.sl_v;
  },
  sl_w: (state) => {
    return state.sl_w;
  },
  sl_x: (state) => {
    return state.sl_x;
  },
  sl_y: (state) => {
    return state.sl_y;
  },
  sl_z: (state) => {
    return state.sl_z;
  },
  sl_za: (state) => {
    return state.sl_za;
  },
  sl_zb: (state) => {
    return state.sl_zb;
  },
  sl_zc: (state) => {
    return state.sl_zc;
  },
  sl_zd: (state) => {
    return state.sl_zd;
  },
  sl_ze: (state) => {
    return state.sl_ze;
  },
  sl_zf: (state) => {
    return state.sl_zf;
  },
  question_c: (state) => {
    return state.question_c;
  },
  question_d: (state) => {
    return state.question_d;
  },
  question_x: (state) => {
    return state.question_x;
  },
  ready_a: (state) => {
    return state.ready_a;
  },
  ready_b: (state) => {
    return state.ready_b;
  },
  ready_c: (state) => {
    return state.ready_c;
  },
  ready_d: (state) => {
    return state.ready_d;
  },
  ready_e: (state) => {
    return state.ready_e;
  },
  ready_f: (state) => {
    return state.ready_f;
  },
  remark_a: (state) => {
    return state.remark_a;
  },
  remark_b: (state) => {
    return state.remark_b;
  },
  remark_c: (state) => {
    return state.remark_c;
  },
  remark_d: (state) => {
    return state.remark_d;
  },
  remark_e: (state) => {
    return state.remark_e;
  },
  remark_f: (state) => {
    return state.remark_f;
  },
};

export const actions = {
  async getDataB({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await ResultService.getResultB(payload);
      commit("update_step", response.data.data.bfsmodule.step);
      commit("update_sl_a", response.data.data.bfsmodule.sl_a);
      commit("update_sl_b", response.data.data.bfsmodule.sl_b);
      commit("update_sl_c", response.data.data.bfsmodule.sl_c);
      commit("update_sl_d", response.data.data.bfsmodule.sl_d);
      commit("update_sl_e", response.data.data.bfsmodule.sl_e);
      commit("update_sl_f", response.data.data.bfsmodule.sl_f);
      commit("update_sl_g", response.data.data.bfsmodule.sl_g);
      commit("update_sl_h", response.data.data.bfsmodule.sl_h);
      commit("update_sl_i", response.data.data.bfsmodule.sl_i);
      commit("update_sl_j", response.data.data.bfsmodule.sl_j);
      commit("update_sl_k", response.data.data.bfsmodule.sl_k);
      commit("update_sl_l", response.data.data.bfsmodule.sl_l);
      commit("update_sl_m", response.data.data.bfsmodule.sl_m);
      commit("update_sl_n", response.data.data.bfsmodule.sl_n);
      commit("update_sl_o", response.data.data.bfsmodule.sl_o);
      commit("update_sl_p", response.data.data.bfsmodule.sl_p);
      commit("update_sl_q", response.data.data.bfsmodule.sl_q);
      commit("update_sl_r", response.data.data.bfsmodule.sl_r);
      commit("update_sl_s", response.data.data.bfsmodule.sl_s);
      commit("update_sl_t", response.data.data.bfsmodule.sl_t);
      commit("update_sl_u", response.data.data.bfsmodule.sl_u);
      commit("update_sl_v", response.data.data.bfsmodule.sl_v);
      commit("update_sl_w", response.data.data.bfsmodule.sl_w);
      commit("update_sl_x", response.data.data.bfsmodule.sl_x);
      commit("update_sl_y", response.data.data.bfsmodule.sl_y);
      commit("update_sl_z", response.data.data.bfsmodule.sl_z);
      commit("update_sl_za", response.data.data.bfsmodule.sl_za);
      commit("update_sl_zb", response.data.data.bfsmodule.sl_zb);
      commit("update_sl_zc", response.data.data.bfsmodule.sl_zc);
      commit("update_sl_zd", response.data.data.bfsmodule.sl_zd);
      commit("update_sl_ze", response.data.data.bfsmodule.sl_ze);
      commit("update_sl_zf", response.data.data.bfsmodule.sl_zf);
      commit("update_question_x", response.data.data.bfsmodule.question_x);
      commit("update_remark_a", response.data.data.bfsmodule.remark_a);
      commit("update_remark_b", response.data.data.bfsmodule.remark_b);
      commit("update_remark_c", response.data.data.bfsmodule.remark_c);
      commit("update_remark_d", response.data.data.bfsmodule.remark_d);
      commit("update_remark_e", response.data.data.bfsmodule.remark_e);
      commit("update_remark_f", response.data.data.bfsmodule.remark_f);
      commit("update_ready_a", response.data.data.bfsmodule.ready_a);
      commit("update_ready_b", response.data.data.bfsmodule.ready_b);
      commit("update_ready_c", response.data.data.bfsmodule.ready_c);
      commit("update_ready_d", response.data.data.bfsmodule.ready_d);
      commit("update_ready_e", response.data.data.bfsmodule.ready_e);
      commit("update_ready_f", response.data.data.bfsmodule.ready_f);
      commit("SET_LOADING", false);
      commit("SET_ERROR", response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_STEPONE", false);
      commit("SET_ERROR", getError(error));
    }
  },
};
