import Vue from "vue";
import Vuex from "vuex";
import * as auth from "@/store/modules/Auth";
import * as moduleA from "@/store/modules/moduleA";
import * as moduleB from "@/store/modules/moduleB";
import * as moduleC from "@/store/modules/moduleC";
import * as moduleD from "@/store/modules/moduleD";
import * as moduleE from "@/store/modules/moduleE";
import * as moduleF from "@/store/modules/moduleF";
import * as moduleG from "@/store/modules/moduleG";
import * as moduleH from "@/store/modules/moduleH";
import * as moduleI from "@/store/modules/moduleI";
import * as moduleJ from "@/store/modules/moduleJ";
import * as moduleK from "@/store/modules/moduleK";
import * as moduleL from "@/store/modules/moduleL";
import * as moduleM from "@/store/modules/moduleM";
import * as moduleN from "@/store/modules/moduleN";
import * as moduleO from "@/store/modules/moduleO";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    auth,
    moduleA,
    moduleB,
    moduleC,
    moduleD,
    moduleE,
    moduleF,
    moduleG,
    moduleH,
    moduleI,
    moduleJ,
    moduleK,
    moduleL,
    moduleM,
    moduleN,
    moduleO,
  },
});
