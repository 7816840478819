<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      md="6"
      class="leftPanel align-center"
      :class="{ 'full-page': $vuetify.breakpoint.mdAndUp }"
    >
      <ta-left-intro />
    </v-col>
    <v-col cols="12" md="6" class="justify-center align-center">
      <v-row no-gutters class="ma-15">
        U komt nu in uw persoonlijke omgeving. Om in te loggen vragen wij u om
        uw e-mailadres in te vullen.<br />
      </v-row>

      <v-row no-gutters class="ma-15">
        <v-col cols="1"></v-col>
        <v-col cols="10">
          <ValidationObserver
            ref="obs"
            v-slot="{ invalid, validated, handleSubmit }"
          >
            <v-form ref="form">
              <BaseValInput
                rules="required|email"
                v-model.trim="email"
                label="email"
                @keyup="lowercase"
              />

              <div class="text-end mr-15">
                <v-btn
                  elevation="15"
                  class="primary"
                  @click="handleSubmit(login)"
                  :disabled="invalid || !validated"
                >
                  Verder</v-btn
                >
              </div>
            </v-form>
          </ValidationObserver>
          <flash-message :error="error" v-if="error" key="error" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// import { mapGetters } from "vuex";
// import { getError } from "@/utils/helpers";
import FlashMessage from "@/components/FlashMessage";
import { getError } from "@/utils/helpers";
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import BaseValInput from "@/components/input/BaseValInput";
export default {
  name: "Login",
  components: { FlashMessage, ValidationObserver, BaseValInput },
  data() {
    return {
      error: null,
      valid: false,
      theme: null,
      email: null,
      emailRules: [
        (v) => !!v || "E-mail is verplicht",
        // eslint-disable-next-line no-useless-escape
        (v) =>
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
            v
          ) || "Dit lijkt geen goed email adres",
      ],
    };
  },
  methods: {
    toggle_dark_mode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    lowercase() {
      this.email = this.email.toLowerCase();
    },
    async login() {
      let data = {
        email: this.email,
      };
      this.error = null;
      // axios.post("/loginchart", data, {
      //   headers: {
      //   },
      // });

      try {
        await this.$store.dispatch("auth/loginA", data);
        if (this.stepOne) {
          this.$store.commit("auth/SET_EMAIL", this.email);
          await this.$router.push({ name: "LoginB" });
          console.log("stap1 af");
        } else {
          console.log("error");
          const error = Error("Dit email adres lijkt niet geheel te kloppen");
          error.name = "Fetch User";
          throw error;
        }
      } catch (error) {
        this.error = getError(error);
      }
    },
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      this.$vuetify.theme.dark = theme === "true";
    }
  },
  computed: {
    ...mapGetters("auth", ["stepOne"]),
  },
};
</script>

<style scoped></style>
